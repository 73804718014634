.app__blogs {
  flex: 1;
  flex-direction: column;
  width: 100%;
}

.app__blog-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  flex-direction: row;

  .app__blogs-item {
    background-color: var(--white-color);
    margin: 2rem;
    padding: 2rem 1rem;
    cursor: pointer;
    border-radius: 15px;
    max-width: 450px;
    height: 200px;

    &:hover {
      box-shadow: 0 0 25px rgba(0, 0, 0, 0.2);
      transition: all 0.3s ease-in-out;
    }

    p {
      font-weight: 400;
      color: var(--gray-color);
      margin-top: 5px;
    }

    .blog-content {
      overflow: hidden;
      width: 400px;
      height: 100px;
    }
  }
}
